import { Slide } from 'react-slideshow-image';
import React from 'react';

const MySlider = ({ slideImages, height, easing }) => {
  return (
    <div>
      <Slide easing={easing}>
        {slideImages.map(({ url, position }, idx) => (
          <div className="each-slide" key={idx}>
            <div
              className={`flex items-center justify-center bg-cover ${position}`}
              style={{ backgroundImage: `url(${url})`, height }}
            />
          </div>
        ))
        }
      </Slide>
    </div>
  );
};

export default MySlider;
