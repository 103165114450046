import MySlider from '../containers/MySlider';
import React from 'react';
import thermorChauffage from '../images/plomberie/thermor-chauffe-eau.jpeg';
import radiateur from '../images/chauffage/radiateur.jpeg';
import radiateur2 from '../images/chauffage/radiateur2.jpeg';
import soudure from '../images/chauffage/soudure.jpeg';
import chaudiere from '../images/chauffage/chaudière.jpeg';

const slideImages = [
    {
        url: radiateur,
        position: 'bg-top'
    },
    {
        url: soudure
    },
    {
        url: chaudiere
    },
    {
        url: radiateur2
    },

];
const Chauffage = () => {
    return (
        <>
            <div className={'flex flex-col items-center justify-center'}>
                <h2 className={'mt-32 mb-16 text-4xl animate__animated animate__tada'}>CHAUFFAGE</h2>
                <p className={'mx-16 mb-16 text-justify'}>
                    Nous pouvons vous proposer différents modes de chauffage. Une étude sera faites au préalable pour vous proposer le meilleur afin d'économiser un maximum.
                </p>
                <div className={'flex items-center w-10/12 flex-wrap justify-around items-center text-white mt-2 bg-black p-5 rounded'}>
                    <div className={'text-center flex flex-col mt-2 animate__animated animate__backInLeft'}>
                        <h3 className={'text-2xl'}>Travaux à neufs</h3>
                        <ul className={'list-disc text-justify'}>
                            <li>pose séche serviette</li>
                            <li>pose de chaudière à gaz</li>
                            <li>pose de chaudière électrique</li>
                        </ul>
                    </div>
                    <div className={'border border-white mx-2 h-full hidden md:block'} style={{ minHeight: '100px' }}/>
                    <div className={'text-center flex flex-col mt-2 animate__animated animate__backInRight'}>
                        <h3 className={'text-2xl'}>Travaux de rénovation</h3>
                        <ul className={'list-disc text-justify'}>
                            <li>entretien du chauffage</li>
                            <li>remplacement de chaudière à gaz</li>
                            <li>entretien chaudière fuel/gaz</li>
                        </ul>
                    </div>
                </div>

                <div className={'flex flex-wrap md:flex-nowrap items-center my-16 p-5 w-10/12 animate__animated animate__jackInTheBox'}>
                    <img height={400} width={400} className={'rounded'} src={thermorChauffage} alt={'thermor-chauffe-eau.png'}/>
                    <div className={'md:p-3'}>
                        <h2 className={'text-3xl'}>TROUVER LE CHAUFFE-EAU ADAPTER À VOS BESOINS</h2>
                        <p>
                            Si vous avez besoin d'optimiser votre espace pensez au chauffe-eau gain de place. Carrés ou plats, ils offrent un encombrement réduit. Ils chauffent plusieurs fois par jour, ne nécessitent pas de stocker l'eau.
                        </p>
                    </div>
                </div>
            </div>
            <div className={'m-16 border rounded'}>
                <MySlider slideImages={slideImages} easing={'ease'} height={'50vh'} />
            </div>
        </>
    );
}

export default Chauffage;
