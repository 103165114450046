import React, { useState } from 'react';


const CguForm = () => {
  const cgu = localStorage.getItem('cgu') === 'true';
  const [open, setOpen] = useState(!cgu);

  const onNot = () => {
    setOpen(false);
    localStorage.setItem('cgu', false);
  };

  const onYes = () => {
    setOpen(false);
    localStorage.setItem('cgu', true);
  };
  return open && (
    <div className={'bottom-0 fixed flex flex-col items-stretch w-full h-full border-t-2 z-10'}>
      <div className={'flex-1 bg-gray-600 opacity-70'} />
      <div className={'bg-white'}>
        <div className={'p-2'}>
          <h2 className={'text-3xl'}>La protection de vos données personnelles est notre priorité</h2>
          <p>Nous utilisons des cookies pour vous proposer une expérience de navigation de qualité sur notre site Web.</p>
        </div>
        <div className={'flex justify-around m-2'}>
          <button onClick={onYes} className={'border p-2 bg-black text-white hover:text-black hover:bg-white'}>Tout accepter</button>
          <button onClick={onNot} className={'border p-2 hover:text-white hover:bg-black'}>Tout refuser</button>
        </div>
      </div>
    </div>
  );
};

export default CguForm;
