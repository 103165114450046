import type { LinkProps } from "react-router-dom";
import {
    Link,
    useMatch,
    useResolvedPath
} from "react-router-dom";

const CustomLink = ({ children, to, ...props }: LinkProps) => {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (
        <div>
            <Link
                className={ match ?
                        'bg-orange text-white px-3 py-2 rounded-md font-medium'
                        : 'text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md font-medium' }
                to={to}
                {...props}
            >
                {children}
            </Link>
        </div>
    );
}

export default CustomLink;
