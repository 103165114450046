import React from "react";
import lampe from '../images/electricite/lampe.jpg';
import maison from '../images/maison.png';
import logoWhite from '../images/logo-white.png';
import plomberie from '../images/plomberie/plomberie.png';
import electricite from '../images/electricite/electricite.png';
import climatisation from '../images/climatisation/climatisation.png';
import chauffage from '../images/chauffage/chauffage.png';
import thermor from '../images/thermor.png';
import cedeo from '../images/cedeo.jpeg';
import aubade from '../images/aubade.jpeg';
import rexel from '../images/rexel.jpeg';
import Photo from '../components/Photo';
import { useNavigate } from "react-router-dom";
import MySlider from '../containers/MySlider';

const slideImages = [
    {
        url: lampe
    },
    {
        url: maison
    },

];
const Home = () => {
    const navigate = useNavigate();
    const hoverClick = (link) => () => {
        navigate(link);
    }
    return (
        <>
            <MySlider height={'93vh'} easing={'ease'} slideImages={slideImages}/>
            <div className={'mx-12'}>
                <img
                  className={'mt-3'}
                  src={logoWhite}
                  alt="This is a logo"
                  width="50"
                  height="50"
                />
                <div className={'my-32 flex flex-wrap md:flex-nowrap text-xl'}>
                    <p className={''}>
                        A l’affut des dernieres innovations et des solutions résonnées il met ses compétences en plomberie, chauffage, électricité ou encore climatisation dans la réalisation de vos projets. Il n’hésite pas à mutualisé avec un réseau de partenaires: maçonnerie, plaquiste, peintre, etc …
                    </p>
                    <p className={'md:mx-2 md:my-0 my-2'}>
                        Il étudira également avec vous vos besoins réel afin d’assurer un travail propre, sérieux et sécuritaire si vous êtes également dans un projet d’autoconstruction, il peut vous proposez ses conseils techniques, élaborer des plans, vous aidez également sur votre chantier en reduisant les couts de prestation.
                    </p>
                    <p className={''}>
                        Il se déplace dans le département de la charente maritime afin de mettre en oeuvre ses compétences et son savoir faire dans la réalisation de vos ouvrages qu’il s’agisse d’une maison neuve, d’une rénovation, remise aux normes, remise en conformite ou bien d’un dépannage.
                    </p>
                </div>
                <div className={'my-32 flex flex-col justify-center items-center'}>
                    <div className={'flex flex-wrap justify-center'}>
                        <Photo txt={'Électricité'} hoverClick={hoverClick('/electricite')} imgType={'cover'} img={electricite} height={400} width={400} alt={'lien vers la partie plomberie'} />
                        <Photo txt={'Plomberie'} hoverClick={hoverClick('/plomberie')} imgType={'cover'} img={plomberie} height={400} width={400} alt={'lien vers la partie plomberie'} />
                    </div>
                    <div className={'flex flex-wrap justify-center'}>
                        <Photo txt={'Climatisation'} hoverClick={hoverClick('/climatisation')} img={climatisation} height={400} width={400} alt={'lien vers la partie plomberie'} />
                        <Photo txt={'Chauffage'} hoverClick={hoverClick('/chauffage')} img={chauffage} height={400} width={400} alt={'lien vers la partie plomberie'} />
                    </div>
                </div>
            </div>
            <div className={'flex flex-col items-center justify-center'}>
                <hr className={'w-10/12 mb-16 border-2 border-black rounded rounded-3xl'}/>
                <h2 className={'text-3xl mb-8'}>NOS PARTENAIRES</h2>
                <div className={'flex flex-wrap items-center mb-16'}>
                    <Photo img={thermor} imgType={'contain'} width={200} height={80}/>
                    <Photo img={aubade} imgType={'contain'} width={200} height={80}/>
                    <Photo img={rexel} imgType={'contain'} width={200} height={80}/>
                    <Photo img={cedeo} imgType={'contain'} width={200} height={80}/>
                </div>
            </div>
        </>
    );
}

export default Home;
