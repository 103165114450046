import logo from '../images/logo.png';
import CustomLink from "../components/CustomLink";
import {useState} from "react";

const TopBar = () => {
    const [open, setOpen] = useState(false);
    const isOpen = () => {
        return open?'block':'hidden';
    }
    const isClose = () => {
        return open?'hidden':'block';
    }
    const handlerOpen = () => {
        setOpen(!open);
    }
    return (
      <nav className="bg-black flex flex-col md:flex-row md:items-center">
          <div className="relative flex items-center justify-between" style={{height: '7vh'}}>
              <div className="flex items-center">
                  <img src={logo} height={'40px'} width={'40px'} alt={'logo of maintenance habitat.'}/>
                  <div className="hidden sm:block sm:ml-6">
                      <div className="flex space-x-4">
                          <CustomLink to="/">Accueil</CustomLink>
                          <CustomLink to={'/plomberie'}>Plomberie</CustomLink>
                          <CustomLink to={'/electricite'}>Électricité</CustomLink>
                          <CustomLink to={'/chauffage'}>Chauffage</CustomLink>
                          <CustomLink to={'/climatisation'}>Climatisation</CustomLink>
                      </div>
                  </div>
              </div>
              <div className="flex items-center sm:hidden mr-1">
                  <button type="button" onClick={handlerOpen} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
                      <span className="sr-only">Open main menu</span>
                      <svg className={isOpen() + ' h-6 w-6'} xmlns="http://www.w3.org/2000/svg" fill="none"
                           viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                d="M4 6h16M4 12h16M4 18h16"/>
                      </svg>

                      <svg className={isClose() + ' h-6 w-6'} xmlns="http://www.w3.org/2000/svg" fill="none"
                           viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" >
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"/>
                      </svg>
                  </button>
              </div>
          </div>

          <div className={isClose() + ' sm:hidden'} id="mobile-menu">
              <div className="px-2 pt-2 pb-3 space-y-1">
                  <CustomLink to="/">Accueil</CustomLink>
                  <CustomLink to={'/plomberie'}>Plomberie</CustomLink>
                  <CustomLink to={'/electricite'}>Électricité</CustomLink>
                  <CustomLink to={'/chauffage'}>Chauffage</CustomLink>
                  <CustomLink to={'/climatisation'}>Climatisation</CustomLink>
              </div>
          </div>
      </nav>
   );
}

export default TopBar;
