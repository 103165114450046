import {Routes, Route, BrowserRouter} from 'react-router-dom';
import Home from "./pages/Home";
import TopBar from "./containers/TopBar";
import BottomBar from "./containers/BottomBar";
import Electricite from './pages/Electricite';
import Climatisation from './pages/Climatisation';
import Plomberie from './pages/Plomberie';
import Chauffage from './pages/Chauffage';
import CguForm from './containers/cguForm';
function App() {
  return (
      <BrowserRouter>
          <div className={'flex flex-col min-h-screen'}>
              <TopBar />
              <div className={'flex-1'}>
                  <Routes>
                      <Route path='/climatisation' element={<Climatisation />} />
                      <Route path='/plomberie' element={<Plomberie />} />
                      <Route path='/chauffage' element={<Chauffage />} />
                      <Route path='/electricite' element={<Electricite />} />
                      <Route path="*" element={<Home/>}/>
                  </Routes>
              </div>
              <BottomBar />
              <CguForm />
          </div>
      </BrowserRouter>
  );
}

export default App;
