import React, { useEffect } from 'react';
import mapboxgl from "mapbox-gl";

const BaseMap = () => {
  mapboxgl.accessToken = 'pk.eyJ1IjoicHJvamV0LXNyLWR3MjIiLCJhIjoiY2t6d3Zra2gxNnlmYjJvbzFuNGRlNDE3OSJ9.2OqYOCzP8j5WxonGy_1uIw';

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: 'mapContainer', // container ID
      style: 'mapbox://styles/mapbox/streets-v11', // style URL
      center: [-0.8073505, 45.559907145965994], // starting position [lng, lat]
      zoom: 16 // starting zoom
    });
    new mapboxgl.Marker().setLngLat([-0.8073505, 45.559907145965994]).addTo(map);
    map.resize();
  }, [])

  return <div id="mapContainer" className="map"/>;
};

export default BaseMap;
