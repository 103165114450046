import { createRef, useEffect, useState } from 'react';

const R = require('ramda');
const Photo = ({
  hoverClick,
  isHover,
  width,
  height,
  txt,
  img,
  alt,
  className,
  imgType
}) => {
  const [hover, setHover] = useState(true);
  const ref = createRef();

  useEffect(() => {
    if (R.isNil(isHover)) setHover(isHover);
  }, [isHover]);

  const onMouseEnter = (e) => {
    if (hover) return;
    ref.current.style.display = 'flex';
  }
  const onMouseLeave = (e) => {
    ref.current.style.display = 'none';
  }
  return (
        <div className={`${className}`} style={{ width, height }} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          <div ref={ref} onClick={hoverClick}
                className={'hidden absolute justify-center items-center bg-gray-500 bg-opacity-50 h-full'}
                style={{ width, height }}>
            <p className={'text-white text-3xl'}>{txt}</p>
          </div>

          <img className={`object-${imgType}`}
               src={img}
               alt={alt}
               style={{ width, height }}/>
        </div>
    );
}

export default Photo;
