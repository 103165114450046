import lumEscalier from '../images/electricite/lum-escalier.jpeg';
import React from 'react';
import MySlider from '../containers/MySlider';
import cheminee from '../images/electricite/cheminée.jpeg';
import SAM from '../images/electricite/SAM.jpeg';
import tableau1 from '../images/electricite/tableau1.jpeg';
import tableau2 from '../images/electricite/tableau2.jpeg';

const slideImages = [
    {
        url: cheminee,
    },
    {
        url: SAM,
    },
    {
        url: tableau1,
    },
    {
        url: tableau2,
    }
];

const Electricite = () => {
    return (
        <>
            <div className={'flex flex-col items-center'}>
                <h2 className={'mt-32 mb-16 text-4xl animate__animated animate__tada'}>ÉLECTRICITÉ GÉNÉRALE</h2>
                <p className={'mx-16 mb-16 text-justify'}>
                    Il vous proposera l'installation la plus conforme et adapter à vos besoins.
                </p>
                <div className={'flex items-center w-10/12 flex-wrap justify-around items-center text-white mt-2 bg-black p-5 rounded'}>
                    <div className={'text-center flex flex-col mt-2 animate__animated animate__backInLeft'}>
                        <h3 className={'text-2xl'}>Travaux à neufs</h3>
                        <ul className={'list-disc text-justify'}>
                            <li>tableau électrique</li>
                            <li>branchement des cables et des interrupteurs</li>
                            <li>installation de radiateurs electrique</li>
                            <li>installation des prises TV/téléphone</li>
                            <li>installation de luminaire</li>
                            <li>installation de routeur wifi/antenne</li>
                        </ul>
                    </div>
                    <div className={'border border-white h-full hidden md:block'} style={{ minHeight: '100px' }}/>
                    <div className={'text-center flex flex-col mt-2 animate__animated animate__backInRight'}>
                        <h3 className={'text-2xl'}>Travaux de rénovation</h3>
                        <ul className={'list-disc text-justify'}>
                            <li>remise aux normes</li>
                            <li>remplacement d'appareillage</li>
                            <li>changement de cables et de disjoncteurs</li>
                            <li>tableau électrique</li>
                            <li>changement de système de chauffage</li>
                            <li>rénovation des luminaires</li>
                        </ul>
                    </div>
                </div>
                <div className={'flex flex-wrap md:flex-nowrap items-center my-16 p-5 w-10/12 animate__animated animate__jackInTheBox'}>
                    <img height={400} width={400} className={'rounded'} src={lumEscalier} alt={'lum-escalier.png'}/>
                    <div className={'md:p-3'}>
                        <h2 className={'text-3xl'}>TOUJOURS AVANCER VERS LA LUMIÈRE</h2>
                        <p>
                            Des solutions techniques de confort, de sécurité et de communication pour concrétiser au mieux votre rêve d’une "maison intelligente".
                        </p>
                    </div>
                </div>
            </div>
            <div className={'m-16 border rounded'}>
                <MySlider slideImages={slideImages} easing={'ease'} height={'50vh'} />
            </div>
        </>
    );
}

export default Electricite;
