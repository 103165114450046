import React from 'react';
import thermorChauffage from '../images/plomberie/thermor-chauffe-eau.jpeg';
import MySlider from '../containers/MySlider';
import chauffeEau from '../images/plomberie/chauffe-eau.jpeg';
import raccord1 from '../images/plomberie/raccord.jpeg';
import wcSsp from '../images/plomberie/wc-ssp.jpeg';

const slideImages = [
    {
        url: raccord1,
        position: 'bg-center'
    },
    {
        url: chauffeEau,
        position: 'bg-top'
    },
    {
        url: wcSsp,
        position: 'bg-bottom'
    },

];
const Plomberie = () => {
    return (
      <>
          <div className={'flex flex-col items-center'}>
              <h2 className={'mt-32 mb-16 text-4xl animate__animated animate__tada'}>PLOMBERIE</h2>
              <p className={'mx-16 mb-16 text-justify'}>
                  Nous pouvons réaliser vos travaux de plomberies avec différents matériaux tel que le cuivre ou encore le multicouche.
              </p>
              <div className={'flex items-center w-10/12 flex-wrap justify-around items-center text-white mt-2 bg-black p-5 rounded'}>
                  <div className={'text-center flex flex-col mt-2 animate__animated animate__backInLeft'}>
                      <h3 className={'text-2xl'}>Travaux à neufs</h3>
                      <ul className={'list-disc text-justify'}>
                          <li>installation WC suspendu</li>
                          <li>installation meuble vasque</li>
                          <li>pose de robinetterie</li>
                          <li>création de salle de bain</li>
                      </ul>
                  </div>
                  <div className={'border border-white h-full hidden md:block'} style={{ minHeight: '100px' }}/>
                  <div className={'text-center flex flex-col mt-2 animate__animated animate__backInRight'}>
                      <h3 className={'text-2xl'}>Travaux de rénovation</h3>
                      <ul className={'list-disc text-justify'}>
                          <li>installation douche a la place d’une baignoire</li>
                          <li>remplacement robinetterie</li>
                          <li>renovation de salle de bain</li>
                          <li>remplacement de chauffe eau</li>
                      </ul>
                  </div>
              </div>
              <div className={'flex flex-wrap md:flex-nowrap items-center my-16 p-5 w-10/12 animate__animated animate__jackInTheBox'}>
                  <img height={400} width={400} className={'rounded'} src={thermorChauffage} alt={'thermor-chauffe-eau.png'}/>
                  <div className={'md:p-3'}>
                      <h2 className={'text-3xl'}>TROUVER LE CHAUFFE-EAU ADAPTER À VOS BESOINS</h2>
                      <p>
                          Si vous avez besoin d'optimiser votre espace pensez au chauffe-eau gain de place. Carrés ou plats, ils offrent un encombrement réduit. Ils chauffent plusieurs fois par jour, ne nécessitent pas de stocker l'eau.
                      </p>
                  </div>
              </div>
          </div>
          <div className={'m-16 border rounded'}>
              <MySlider slideImages={slideImages} easing={'ease'} height={'50vh'} />
          </div>
      </>
    );
}

export default Plomberie;
