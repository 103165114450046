import thermorClim from '../images/climatisation/thermor-clim.jpeg';
import React from 'react';
import MySlider from '../containers/MySlider';
import thermorPompe from '../images/climatisation/thermor-pompe.jpg';
import thermorClim2 from '../images/climatisation/thermor-clim2.png';
import climatisatisation from '../images/climatisation/climatisation.png';
import lgClim from '../images/climatisation/lg-clim.jpeg';
const slideImages = [
    {
        url: thermorPompe,
        position: 'bg-center'
    },
    {
        url: thermorClim2,
        position: 'bg-center'
    },
    {
        url: climatisatisation,
        position: 'bg-center'
    },
    {
        url: lgClim,
        position: 'bg-center'
    }
];
const Climatisation = () => {
    return (
        <>
            <div className={'flex flex-col items-center'}>
                <h2 className={'mt-32 mb-16 text-4xl animate__animated animate__tada'}>CLIMATISATION</h2>
                <p className={'mx-16 mb-16 text-justify'}>
                    Nous vous proposons un mode de chauffage économique ,modulable en programmation et des fonctions wifi pour une gestion simplifier nous travaillons avec des Clim de marque Thermor car elles offrent de multiples possibilités.
                </p>
                <div className={'text-center text-white mt-2 bg-black p-5 w-10/12 rounded'}>
                    <p className={'animate__animated animate__bounceIn'}>Au sol, encastrée ou semi encastrée, la gamme Nagano est la solution confort été comme hiver pour les vérandas ou combles aménagés.</p>
                    <h3 className={'text-2xl my-2 animate__animated animate__rubberBand'}>Description</h3>
                    <div className={'flex md:justify-around flex-wrap p-2 animate__animated animate__backInLeft'}>
                        <ul className={'list-disc text-justify'}>
                            <li>il existe plusieurs types de pompe à chaleur (air/air, air/eau, ...)</li>
                            <li>mise en place d'un gainable</li>
                            <li>mise en place de multi split</li>
                        </ul>
                        <ul className={'list-disc text-justify'}>
                            <li>Entretien et dépannage</li>
                            <li>Pompe à chaleur piscine</li>
                        </ul>
                    </div>
                </div>
                <div className={'flex flex-wrap md:flex-nowrap items-center my-16 p-5 w-10/12 animate__animated animate__jackInTheBox'}>
                    <img height={400} width={400} className={'rounded'} src={thermorClim} alt={'thermor-chauffe-eau.png'}/>
                    <div className={'md:p-3'}>
                        <h2 className={'text-3xl'}>UN PEU D'AIR PUR</h2>
                        <p>
                            Ayez une qualité de l’air sans précédent avec les climatiseurs muraux Nagano Murale Pure. Equipée du système UV-C, votre climatisation désinfecte et rafraichit l’air ambiant pour vous offrir un environnement sain et agréable.
                         </p>
                    </div>
                </div>
            </div>
            <div className={'m-16 border rounded'}>
                <MySlider slideImages={slideImages} easing={'ease'} height={'50vh'} />
            </div>
        </>
    );
}

export default Climatisation;
